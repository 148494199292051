import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Industries from "../components/Repeating/Industries";
import Testimonials from "../components/Repeating/Testimonials";
import FAQs from "../components/Repeating/FAQs";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalTeamMembers from "../components/Modal/ModalTeamMembers";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
    const faqs = [
        {
          question:
            "Can’t I just have my current CPA prepare my company’s tax returns?",
          answer: (
            <>
              <p className="mb-0">
              Yes and No. Your current CPA can prepare the return, but without specialized knowledge of IRS Code Sections 280E and 471, your CPA might not be maximizing your tax savings. Or worse, your CPA may be making errors that will open you up to IRS audits resulting in significant taxes and penalties.
              </p>
            </>
          ),
        },
        {
          question: "I have operations in multiple states, do I need a CPA in each state?",
          answer: (
            <>
              <p className="mb-0">
              Cultivators should be performing in-depth analysis of costs associated with growing and packaging to maximize the cost of their inventory, and therefore maximize the deduction for the cost of goods sold.
              </p>
            </>
          ),
        },
        {
          question:
            "Does my business need to file more than income taxes?",
          answer: (
            <>
              <p className="mb-0">
              Quite possibly. Most states require sales taxes, plus recreational or medical cannabis sales and excise taxes. Dark Horse CPAs will assist you in meeting all your tax filing requirements.
              </p>
            </>
          ),
        },
        {
          question:
            "I haven’t filed tax returns for several years. Can you help me?",
          answer: (
            <>
              <p className="mb-0">
              Absolutely! We’ll get all of those overdue tax returns filed for you and we’ll work with the IRS and state tax authorities on your behalf to reduce any penalties to the maximum extent allowable.
              </p>
            </>
          ),
        },
        {
            question:
              "I received a letter from the IRS or the state informing me that my tax return is being audited. Can you help?",
            answer: (
              <>
                <p className="mb-0">
                Of course! We provide unparalleled tax audit representation that will result in you getting the most favorable outcome possible. Furthermore, we deal directly with the IRS or state on your behalf so you don’t have to worry about being put on the spot and disclosing information that will harm you. Think of us like an attorney representing you before the IRS or state.
                </p>
              </>
            ),
          },
          {
            question:
              "I’m not confident that last year’s tax return was done correctly or worry I left deductions on the table. Will you review those tax returns to address these doubts?",
            answer: (
              <>
                <p className="mb-0">
                Of course. That’s part of our onboarding process with new clients. We’ll let you know if there are any red flags or missed opportunities on those returns. If necessary, we can amend previous tax returns to make sure those issues or opportunities are taken care of.
                </p>
              </>
            ),
          },
        
      ];

  const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Cannabis Tax Services | Dark Horse Cannabis CPA"
        description="Tax compliance is important for any business, especially so in the cannabis industry. We provide expertise to handle all the various cannabis taxes for you!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Taxes for Cannabis</h1>
              <p>
              Tax compliance and tax planning are important for any business, but especially so in the cannabis industry. We provide expertise, allowing your business to operate in an environment with complex tax laws.
              </p>
              <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
            </div>
            <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>
      <section className="relative mb-12 md:mb-12">
            <div className="container py-6">
            <div className="mx-auto mb-[20px] text-center">
            <GatsbyImage image={data.head.childImageSharp.gatsbyImageData} />
            </div>
              <h3 className="text-center mx-auto">Did you know?</h3>
              <p className="text-center mx-auto max-w-[680px]">Proper tax planning throughout the year is the single most effective way to minimize your tax burden. Your options are very limited after the year is over.</p>
              
            </div>
          </section>
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-2">
              <h2>An Ever-Changing Tax Landscape</h2>
              <p>
              The tax rules surrounding cannabis can rapidly change at the state and federal levels. You want a team on your side that’s at the leading edge as regulations shift and law develops. The Dark Horse Cannabis CPAs team is always ready to advise you on what’s coming ‘round the corner. We operate in states across the country, with specific knowledge of the major cannabis markets.
              </p>
              
            </div>
            <div className="relative order-1 md:order-1">
              <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>


      <section className="mb-20 pt-00 md:mb-28">
        <div className="container">
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Tax Planning, Tax Preparation, Tax Everything</h2>
        
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative">
              <ul className="border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                  <li>Tax Planning Guidance under IRS 280E </li>
                  <li>Tax Preparation </li>
                  <li>Tax Estimate Calculations</li>

              </ul>
            </div>
            <div className="relative">
             <ul className="border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                 <li>Amended Tax Returns</li>
             <li>Non-filed Returns</li>
             <li>Sales Tax Return Preparation</li>
             </ul>

            </div>
            <div className="relative">
             <ul className="border-l-2 px-6 border-[#CEE2E6] styled-list-plus">
                 <li>Mergers, Sales, and Acquisitions</li>
                 <li>Tax Audit Support and Defense</li>
                 <li>Payroll Tax Problems
</li>
             </ul>
            </div>
          </div>
          </div>
      </section>


      <section className="mb-20 pt-10 md:mb-20">
        
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Come One, Come All</h2>
        <p className="max-w-[640px] m-auto">We work with all businesses in the cannabis industry—from seed to sale!</p>
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            <div className="relative">
              <AniLink
                fade
                to="/cannabis-industry/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.industry.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Overall Cannabis Industry</p>
                
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/cultivators-growers-manufacturers/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.cultivators.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Cultivators & Growers</p>
               
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/dispensaries-retail/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.dispensaries.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Dispensaries</p>
                
              </AniLink>
            </div>
          </div>
       
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 text-left">
            <h2>Meet the Cannabis CPA Team</h2>
            <p className="max-w-[728px]">When you work with Dark Horse, you work with people. Get to know our people below.</p>
          </header>
          {teamMembersNodes.length > 0 && (
            <div className="grid grid-cols-2 gap-y-6 gap-x-6 md:grid-cols-3 lg:gap-x-10">
              {teamMembersNodes.map((item, i) => (
                <div key={i}>
                  <button
                    data-modal-open="modal-team-members"
                    onClick={() => setSlideIndex(i)}
                    className="group text-left font-normal no-underline"
                  >
                    <div className="mb-2.5 overflow-hidden rounded-4xl md:mb-3.5">
                      <GatsbyImage
                        image={item.headshot.asset.gatsbyImageData}
                        className="over w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="text-lg font-bold text-black md:text-xl">
                      {item.name}
                    </div>
                    <div className="mb-8 text-sm font-bold uppercase tracking-wide text-primary-900/40">
                      {item.title}
                    </div>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <Testimonials category="cannabis" />
      <FAQs heading="Frequently Asked Questions" uniqueFaqs={faqs} />
      <section className="pt-20 pb-20 md:pb-32">
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
        <div className="md:col-span-8 md:col-start-1">
        <GatsbyImage image={data.whyus.childImageSharp.gatsbyImageData} className="rounded-2xl"/>

          </div>
        <div className="md:col-span-4 md:col-end-13">
            <h2>Why Dark Horse?</h2>
            <p>
            We work in the industry because we are passionate about the industry. We want to see your Canna-Business succeed, and we'll do everything we can to help you along the way.
            </p>
            <ButtonWithIcon
              href="/about-us/"
              text="Meet Our People"
              altStyle={1}
            />
          </div>
          
          
        </div>
      </div>
    </section>
     
      <CallToAction />
      <ModalTeamMembers slideIndex={slideIndex} slides={teamMembersNodes} />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    horseHead: file(relativePath: { eq: "global/logo-head-black.svg" }) {
      publicURL
    }
    horseHeadOrange: file(relativePath: { eq: "global/logo-head-orange.svg" }) {
      publicURL
    }
    head: file(relativePath: { eq: "home/horse-head.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 55)
      }
    }
    whyus: file(
        relativePath: { eq: "home/Why Dark Horse_.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    rotatingWords: file(
      relativePath: { eq: "global/rotating-words-cfo-black.svg" }
    ) {
      publicURL
    }
    hero: file(
      relativePath: { eq: "home/1.0 Hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    intro: file(
        relativePath: { eq: "home/2.0 Tax Landscape.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
    dashboard: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/dashboard.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    cultivators: file(
        relativePath: { eq: "home/Cultivators & Manufacturers.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      dispensaries: file(
        relativePath: { eq: "home/Dispensaries.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
      industry: file(
        relativePath: { eq: "home/Overall Cannabis Industry.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 768)
        }
      }
    reports: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/reports.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    teamMembers: allSanityTeamMember(
        filter: {industries: {elemMatch: {}}, industriesFilter: {elemMatch: {title: {eq: "Cannabis"}}}}
      ) {
        edges {
          node {
            id
            name
            headshot {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 564)
                title
              }
            }
            directPhoneNumber
            emailAddress
            linkedinProfile
            hubspotID
            _rawBiography
            title
          }
        }
      }
  }
`;

export default Page;
